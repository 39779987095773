<template>
  <ul class="flex select-none gap-1.5">
    <template
      v-for="locale in locales"
      :key="locale"
    >
      <li>
        <NuxtLink
          class="link-header font-medium uppercase text-white !underline transition-colors hover:text-green lg:text-blue-lighter"
          :class="{'cursor-default !text-green': locale.code === locale}"
          :to="switchLocalePath(locale.code)"
        >
          {{ locale.code }}
        </NuxtLink>
      </li>
      <li class="font-medium text-white last:hidden lg:text-blue-lighter">
        /
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
const {locales} = useI18n();
const switchLocalePath = useSwitchLocalePath();
</script>
